/* React */
import React, { useEffect, useState } from 'react';

/* Stles */
import style from './PriceFilter.module.css'

const PriceFilter = ({ MAX_PRICE_VALUE = 10000, MIN_PRICE_VALUE = 0, onPriceChange, isReset, onReset }) => {

    const [minPrice, setMinPrice] = useState(MIN_PRICE_VALUE);
    const [maxPrice, setMaxPrice] = useState(MAX_PRICE_VALUE);

    useEffect(() => {
        setMinPrice(MIN_PRICE_VALUE)
        setMaxPrice(MAX_PRICE_VALUE)
    }, [MAX_PRICE_VALUE, MIN_PRICE_VALUE])

    useEffect(() => {
        if (isReset) {
            setMinPrice(MIN_PRICE_VALUE)
            setMaxPrice(MAX_PRICE_VALUE)
            onReset()
        }
    }, [MAX_PRICE_VALUE, MIN_PRICE_VALUE, onReset,isReset])

    const handlePriceChange = (e, type) => {
        const value = parseInt(e.target.value);

        if (type === 'min' && value <= maxPrice - 100) {
            setMinPrice(value)
            onPriceChange(value, maxPrice)
        } else if (type === 'max' && value >= minPrice + 100) {
            setMaxPrice(value)
            onPriceChange(minPrice, value)
        }
    };

    return (
        <div className={style['input-range-wrapper']}>
            {MAX_PRICE_VALUE === MIN_PRICE_VALUE ?
                <></>
                :
                <div className={style['range-input']}>
                    <input
                        type="range"
                        min={MIN_PRICE_VALUE}
                        max={MAX_PRICE_VALUE}
                        value={minPrice}
                        step={100}
                        onChange={(e) => {
                            handlePriceChange(e, 'min')
                        }}
                        className={style['min-range']}
                    />
                    <input
                        type="range"
                        min={MIN_PRICE_VALUE}
                        max={MAX_PRICE_VALUE}
                        value={maxPrice}
                        step={100}
                        onChange={(e) => {
                            handlePriceChange(e, 'max')
                        }}
                        className={style['max-range']}
                    />
                    <div className={style['slider-container']}>
                        <div
                            className={style['progress']}
                        // style={{ left: `${(minPrice / MAX_PRICE_VALUE) * 100}%`, right: `${(1 - maxPrice / MAX_PRICE_VALUE) * 100}%` }}
                        ></div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PriceFilter;

