export const calculateTotal = (adults, adultPrice, children, childrenPrice) => {
    return (parseInt(adults) * parseFloat(adultPrice)) + (parseInt(children) * parseFloat(childrenPrice))
}

/**
 * Return minutes passed from 00:00
 * @param {string} time 
 */
export const timeToMinutes = time => {
    if (typeof time === "number") return time;
    if (time.indexOf(":") < 0) return time;
    let [hours, minutes] = time.split(':');
    return hours * 60 + minutes * 1
}

export const convertMinutesToHour = time => {
    if (typeof time === 'number') {
        const hours = Math.floor(time / 60).toString().padStart(2, "0");
        const minutes = (time % 60).toString().padStart(2, "0");
        const fullTime = hours + ":" + minutes;
        return fullTime;
    }
    return time;
}

export const formatPrice = (price, fraction = 2) => {
    return parseFloat(price).toLocaleString(undefined, {minimumFractionDigits: fraction})
}

export const round = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

