
export const dateTimeToString = d => {
    // in case 'd' is a firebase timestamp object
    if (typeof d === 'object' && d.constructor.prototype.hasOwnProperty('toDate')) {
        d = d.toDate()
    } else {
        d = new Date(d)
    }

    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var hh = d.getHours() ?? '00';
    var min = d.getMinutes() ?? '00';

    let date = [d.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
    ].join('-');

    let time = `T${(hh > 9 ? '' : '0') + hh}:${(min > 9 ? '' : '0') + min}`
    return date + time
}

/**
 * 
 * @param {Date} d 
 * @returns A simplified format of the date exp. 24-07-22 21:26
 */
export const dateTimeToStringShorten = d => {
    // in case 'd' is a firebase timestamp object
    if (typeof d === 'object' && d.constructor.prototype.hasOwnProperty('toDate')) {
        d = d.toDate()
    } else {
        d = new Date(d)
    }

    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var hh = d.getHours() ?? '00';
    var min = d.getMinutes() ?? '00';

    let date = [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        d.getFullYear() - 2000
    ].join('-');

    let time = ` ${(hh > 9 ? '' : '0') + hh}:${(min > 9 ? '' : '0') + min}`
    return date + time
}

export const dateToString = d => {
    // in case 'd' is a firebase timestamp object
    if (typeof d === 'object' && d.constructor.prototype.hasOwnProperty('toDate')) {
        d = d.toDate()
    } else {
        d = new Date(d)
    }

    var mm = d.getMonth() + 1;
    var dd = d.getDate();

    let date = [d.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
    ].join('-');

    return date
}
export const dateToStringDMV = (d, separator = '-') => {
    // in case 'd' is a firebase timestamp object
    if (typeof d === 'object' && d.constructor.prototype.hasOwnProperty('toDate')) {
        d = d.toDate()
    } else {
        d = new Date(d)
    }

    var mm = d.getMonth() + 1;
    var dd = d.getDate();

    let date = [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        d.getFullYear(),
    ].join(separator);

    return date
}

export const getOnlyTime = date => {
    var hh = date.getHours() ?? '00';
    var min = date.getMinutes() ?? '00';
    let time = ` ${(hh > 9 ? '' : '0') + hh}:${(min > 9 ? '' : '0') + min}`
    return time
}


export const dateTimeBeautify = dateTime => {
    // in case 'd' is a firebase timestamp object
    if (typeof dateTime === 'object' && dateTime.constructor.prototype.hasOwnProperty('toDate')) {
        dateTime = dateTime.toDate()
    } else {
        dateTime = new Date(dateTime)
    }
    return dateToStringDMV(dateTime, '/') + ' ' + getOnlyTime(dateTime)
}

export const dateBeautify = (dateTime, divider = ".") => {
    // in case 'd' is a firebase timestamp object
    if (typeof dateTime === 'object' && dateTime.constructor.prototype.hasOwnProperty('toDate')) {
        dateTime = dateTime.toDate()
    } else {
        dateTime = new Date(dateTime)
    }
    return dateToStringDMV(dateTime, divider)
}