import React, { useEffect, useRef, useState } from 'react';
import style from './SearchComponent.module.css';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { validDate } from '../../utils/generalUtils';

const HOME_ITEMS = [
  {
    id: "1",
    icon: "fas fa-ship",
    text: "Boat Trips",
    path: "/",
  },
  {
    id: "2",
    icon: "fas fa-plane-departure",
    text: "Airport Shuttle",
    path: "/",
    disable: true,
  },
  {
    id: "3",
    icon: "fas fa-hiking",
    text: "Activities",
    path: "https://helloalbania.eu/",
    disable: false,
  },
];
const SearchComponent = ({ cities, isSearchPage = false }) => {

  const citySelector = useRef();
  const navigate = useNavigate();

  /* S T A T E  H O O K S */
  const [searchParams] = useSearchParams();
  const [isValidCity, setIsValidCity] = useState(false);
  const [activeTabId, setActiveTabId] = useState(HOME_ITEMS[0].id);
  const [selectedCity, setSelectedCity] = useState(0);
  const [numberOfAdult, setNumberOfAdult] = useState(2);
  const [numberOfChildren, setNumberOfChildren] = useState(0);
  const [numberOfPeopleToggle, showNumberOfPeople] = useState(false);

  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);

  /* H A N D L E R S */
  const citySelectHandler = (e) => {
    setSelectedCity(e.target.value);
    localStorage.setItem("selectedCity", e.target.value);
  };

  const datePickerHandler = (e) => {
    setDate(e.target.value);
  };

  /* Trigger animation shake & vibration if city is not valid */
  const animateWarning = (ref) => {
    const classes = [
      "animate__headShake",
      "animate__animated",
      "border-warning",
    ];
    ref.current.classList.add(...classes);

    if (window.hasOwnProperty("navigator")) {
      try {
        navigator.vibrate(200);
      } catch (err) {
        console.warn("Vibrate device not supported.");
      }
    }

    ref.current.addEventListener("animationend", () => {
      ref.current.classList.remove(...classes);
    });
  };

  /* H E L P E R S */
  const buildSearchParams = () => {
    let rawParams = {
      cityId: selectedCity,
      adults: numberOfAdult,
      children: numberOfChildren,
      date: date,
    };
    return new URLSearchParams(rawParams).toString();
  };

  const handleSubmitSearch = () => {
    if (!validDate(date)) {
      alert("Date is incorrect");
      return;
    }

    if (isValidCity) {
      return navigate(`/searchList?${buildSearchParams()}`, { forceRefresh: true });
    }

    animateWarning(citySelector);
  };

  useEffect(() => {
    if (isSearchPage && selectedCity && numberOfAdult && numberOfChildren && date && isValidCity) {
      handleSubmitSearch();
    }
  }, [selectedCity, numberOfAdult, numberOfChildren, date]);

  /* L I F E C Y C L E  H O O K S */
  // Route watch
  useEffect(() => {
    setSelectedCity(
      searchParams.get("cityId") || localStorage.getItem("selectedCity") || 0
    );
    setNumberOfAdult(searchParams.get("adults") || 1);
    setNumberOfChildren(searchParams.get("children") || 0);
    setDate(searchParams.get("date") || new Date().toISOString().split("T")[0]);
  }, [searchParams]);

  useEffect(() => {
    setIsValidCity(parseInt(selectedCity) !== 0);
  }, [selectedCity]);

  /* J S X  P A R T I A L  C O M P O N E N T S */
  /* if button is disabled don't apply events*/
  const categoryButtons = (item) => {

    return (
      <div
        className={
          style.button +
          (activeTabId === item.id ? ` ${style.activeButton}` : "") +
          (!!item.disable ? ` ${style.disableBtn}` : "") // disabled
        }
        key={item.id}
        onClick={!item.disable ? () => {
          setActiveTabId(item.id);
          window.location = item.path
        } : () => { }
        } // don't apply event if disabled
      >
        <i className={item.icon}></i>
      </div >
    );
  };

  const numberOfPeopleSelector = () => {
    if (numberOfPeopleToggle) {
      return (
        <div className="d-flex row-xs gap-1 f-100">
          <div className="row centered f-50">
            <button
              aria-label="Minus"
              onClick={() => setNumberOfAdult((n) => parseInt(n) - 1)}
              disabled={numberOfAdult === 1}
              className={style.plusMinus}
            >
              <i className={`fa fa-minus ${style.logo}`} />
            </button>
            <div>
              <input
                aria-label="Number"
                className={style.formInput}
                type="number"
                value={parseInt(numberOfAdult).toString()}
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  let formattedVal = Math.abs(parseInt(value));
                  if (formattedVal === 0) formattedVal = 1;
                  setNumberOfAdult(formattedVal);
                }}
                onBlur={(e) => {
                  if (!e.target?.value) {
                    setNumberOfAdult(1);
                  }
                }}
              />
            </div>
            <button
              onClick={() => setNumberOfAdult((n) => parseInt(n) + 1)}
              className={style.plusMinus}
              aria-label="Plus"
            >
              <i className={`fa fa-plus ${style.logo}`}></i>
            </button>
            <span
              className={`fas fa-male fa-lg text-primary ${style.inlineIcon} ${style.borderLeftNone}`}
            ></span>

          </div>
          <div className="row centered f-50">
            <button
              aria-label="Plus Minus"
              onClick={() => setNumberOfChildren((n) => n - 1)}
              disabled={numberOfChildren === 0}
              className={style.plusMinus}
            >
              <i className={`fa fa-minus ${style.logo}`} />
            </button>
            <div>
              <input
                className={style.formInput}
                aria-label="Number"
                type="number"
                value={parseFloat(numberOfChildren).toString()}
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  let formattedVal = Math.abs(parseInt(value));
                  setNumberOfChildren(formattedVal);
                }}
                onBlur={(e) => {
                  if (!e.target?.value) {
                    setNumberOfChildren(0);
                  }
                }}
              />
            </div>
            <button
              onClick={() => setNumberOfChildren((n) => parseInt(n) + 1)}
              className={style.plusMinus}
              aria-label="Plus"
            >
              <i className={`fa fa-plus ${style.logo}`} />
            </button>
            <span
              className={`fas fa-child text-primary ${style.inlineIcon} ${style.borderLeftNone}`}
            ></span>
          </div>
        </div>
      );
    }
    return (
      <div className="row-sm f-100">
        <input
          aria-label="Number"
          className="f-100"
          onClick={(_) => {
            showNumberOfPeople(true);
          }}
          value={`${numberOfAdult} adults, ${numberOfChildren} children`}
          onChange={() => { }}
        />
      </div>
    );
  };
  return (
    <div className={style.wrapper}>

      <h1 className={style.h1} >Boat Trips and Tour in Albania</h1>
      <h2 className={style.p}>What Are You Looking For?</h2>

      <div className={style.buttonsWrapper}>
        {HOME_ITEMS.map((item) => (
          <div className={style.contentWrapper} key={`tab-${item.id}`}>
            {categoryButtons(item)}
            <div
              className={
                style.buttonText +
                (activeTabId === item.id ? ` ${style.activeButtonText}` : "")
              }
            >
              {item.text}
            </div>
          </div>
        ))}
      </div>

      <select
        ref={citySelector}
        className={style.cityLists}
        id="cities"
        value={selectedCity}
        onChange={citySelectHandler}
      >
        <option value={0}>Select city</option>
        {cities.map((city) => {
          return (
            <option value={city.id} key={`city-${city.id}`}>
              {city.data.name}
            </option>
          );
        })}
      </select>

      <div className={`${style.searchPeopleWrapper} row-md mt-1 gap-1`}>
        <div className="row-sm f-50">{numberOfPeopleSelector()}</div>

        <div className="row-sm centered f-50">
          <input
            type="date"
            aria-label="date"
            value={date}
            min={today}
            onChange={datePickerHandler}
            className={`f-100 ${style.datePicker}`}
            onBlur={(e) => validDate(e.target.value)}
          />
        </div>
      </div>
      <div
        className={`${style.searchbtn} cursor-pointer`}
        onClick={handleSubmitSearch}
      >
        Search
      </div>
    </div>
  );
};

export default SearchComponent;