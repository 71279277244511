/* React */
import React, { useLayoutEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";

/* Components */
import Filters from "./Filters";
import Popup from "../../components/Listing/Popup";
import Sidebar from "../../components/Sidebar/Sidebar";
import SearchBanner from "../../components/SearchBanner/SearchBanner";
import Loading from "../../loading";
import SearchComponent from "../../components/Search/SearchComponent";

/* Styles */
import style from "./SearchListPage.module.css";

/* Constants */
const ORDER_OPTIONS = [
  "Default",
  "Price: Low to High",
  "Price: High to Low",
];

const SearchListPage = () => {

  /* Selector */
  const orderSelector = useRef();
  const [selectedOrder, setSelectedOrder] = useState(0);

  const { data, cities } = useLoaderData();
  const [resultList, setResultList] = useState([]);
  // const [cities, setCities] = useState([]);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [total, setTotal] = useState({
    max: 0,
    min: 0
  });
  const [isMobile, setIsMobile] = useState(false);
  const [forceReset, setForceReset] = useState(false);
  const scrollRef = useRef(null);

  /* Filter State */
  const [categories, setCategories] = useState([])
  const [duration, setDuration] = useState([])
  const [times, setTimes] = useState([])
  const [filters, setFilters] = useState()

  useEffect(() => {
    /* Sorry */
    const timer = setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, 0);

    return () => clearTimeout(timer);
  }, []);


  const searchHandler = async () => {
    setLoading(true);

    try {
      setResultList(data);
      setCategories([...new Set([...data.map(trip => trip.data.category)])])

      const durations = [...new Set(data.map(trip => trip.data.duration))]
        .filter(duration => duration !== undefined);
      setDuration(durations.sort())

      const allTimes = [...new Set(data.flatMap(trip => trip.data.times))]
      setTimes(allTimes)

      const max = Math.max(...data.map(trip => trip.data.totalCost))
      const min = Math.min(...data.map(trip => trip.data.totalCost))
      setTotal({ max, min })

    }
    catch (err) {
      setResultList([])
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    searchHandler();
  }, [data]);

  const changeOrder = (e) => {
    setSelectedOrder(e.target.value);
    const order = ORDER_OPTIONS[e.target.value];
    let sortedList = [...resultList];

    switch (order) {
      case "Price: Low to High":
        sortedList = sortedList.sort((a, b) => a.data.totalCost - b.data.totalCost);
        break;
      case "Price: High to Low":
        sortedList = sortedList.sort((a, b) => b.data.totalCost - a.data.totalCost);
        break;
      case "Default":
        sortedList = data;
        break;
      default:
        break;
    }

    setResultList(sortedList);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Results: Boat Tours {date ? `on ${date}` : ''}</title>
        <link rel="canonical" href="https://www.boattrips.al/searchList" />
        <meta name="description" content="Discover top-rated vacation booking sites on our search page. Book boat rides and find affordable trips for your next adventure. Experience the trip of a lifetime with our trusted travel partners." />
        <meta name="keywords" content="Cheapest vacation booking sites, best sites to book trips,book a boat ride,find cheap trips,book your vacation,boat rides near me " />
      </Helmet>

      <div className={style.pageWrapper}>
        <Sidebar />
        <div className={style.contentWrapper}>
          <div className={`${style.searchPageWrapper}`}>
            <div className={`row row-lg f-100`}>
              <div className={`${style.componentBoxSearch}`}>
                {
                  cities.length !== 0
                    ? <SearchComponent isSearchPage={true} cities={cities} />
                    : <Loading />
                }
                {!isMobile && resultList && resultList.length > 0 && (
                  <Filters
                    categories={categories}
                    durations={duration}
                    departureTimes={times}
                    MAX_PRICE_VALUE={total.max}
                    MIN_PRICE_VALUE={total.min}
                    onButtonClick={(data) => { setFilters(data) }}
                    forceReset={forceReset}
                    handleForceReset={() => setForceReset(false)}
                  />
                )}
              </div>

              <div ref={scrollRef} className={`${style.componentBoxElements}`}>
                <div className={isMobile ? style.searchHeaderMobile : style.searchHeaderWrapper}>
                  <h1 className={`p-0 mb-0 text-primary ${style.h1}`}>Results</h1>

                  <div className={style.filterWrapper}>
                    {
                      isMobile && (
                        <button
                          className={style.filterButton}
                          onClick={() => setShowFilterModal(true)}
                        >
                          Filter
                        </button>
                      )
                    }

                    <select
                      ref={orderSelector}
                      className={style.orderList}
                      id="order"
                      value={selectedOrder}
                      onChange={changeOrder}
                    >
                      <option value={0} defaultValue disabled>Order by</option>
                      {ORDER_OPTIONS.map((option, index) => {
                        return (
                          <option value={index} key={`order-${index}`}>
                            {option}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                </div>
                <SearchBanner resultList={resultList} loading={loading} filters={filters} handleReset={() => setForceReset(true)} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showFilterModal && resultList && resultList.length > 0 && (
        <Popup onClose={() => setShowFilterModal(false)} title={"Filters"}>
          <Filters
            categories={categories}
            durations={duration}
            departureTimes={times}
            MAX_PRICE_VALUE={total.max}
            MIN_PRICE_VALUE={total.min}
            onButtonClick={(data) => { setFilters(data) }}
            forceReset={forceReset}
            handleForceReset={() => setForceReset(false)}
            isMobile={true}
          />
        </Popup>
      )}
    </>
  );
};

export default SearchListPage;
