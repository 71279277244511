/* React */
import React from "react";
import { Link } from "react-router-dom";

/* Components */
import TimeGallery from "../Filters/TimeGallery/TimeGallery";

/* Styles */
import style from "./TicketMobile.module.css";

/* Utils */
import { decodeContinueLink } from "../../utils/urlUtils";

const TicketMobile = ({
    boatId,
    title,
    date,
    adults,
    children,
    place,
    img,
    selectedTime,
    times,
    total,
    navigateToTour,
    continueLink,
    handleTimeSelection,
    showInfo
}) => {

    return (
        <div className={style.itemWrapper}>
            <div className={style.mainWrapepr}>
                <div className={style.infoWrapper}>

                    <img
                        src={`${!!img ? img : process.env.PUBLIC_URL + "/favicon.ico"}`}
                        alt={title}
                        onClick={navigateToTour}
                        className={style.boatImageTicket}
                    />


                    <div className={style.ticketInfo}>
                        <div className={style.topInfoTitle}>
                            <div
                                className={`${style.titleBoatTicket} cursor-pointer`}
                                onClick={navigateToTour}
                            >
                                {title}
                            </div>

                            {showInfo && (
                                <Link to={`/boatDetails?id=${boatId}&continue=${continueLink}`}>
                                    <i className={`${style.iconListItem} fas fa-circle-info`}></i>
                                </Link>
                            )}
                        </div>

                        <div className={style.topInfo}>
                            <div className={style.iconListCalendar} onClick={navigateToTour}>
                                <i className={`${style.iconListItem} fas fa-calendar-alt d-none-sm `}></i>
                                <div className={style.startTripDate}>{date}</div>
                            </div>
                            {times && times.length > 1 ?
                                <TimeGallery
                                    times={times}
                                    selectedTime={selectedTime}
                                    setSelectedTime={handleTimeSelection}
                                />
                                :
                                <div className={place ? style.timeOfDep : style.timeOfDepNoPlace}>{selectedTime}</div>
                            }
                        </div>

                        <div className={style.moreInfoWrapper} onClick={navigateToTour}>
                            <div className={style.iconWrapper}>
                                <div className={style.peopleList}>
                                    <div className={style.iconList}>
                                        <i className={`${style.iconListItem} fa fa-male fa-lg`}></i>
                                        <span>x</span>
                                        <div className={style.peopleNumber}>{adults}</div>
                                    </div>
                                    <div className={style.iconListChild} >
                                        <i className={`${style.iconListItem} fa fa-child `}></i>
                                        <span>x</span>
                                        <div className={style.peopleNumber}>{children}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.buttonWrapper}>
                <div className={style.pricePerPerson}>{total} L</div>
                <Link to={`/bookNow?${decodeContinueLink(continueLink)}`}>
                    <button
                        aria-label="Book Now" className={style.itemButton}>
                        Book
                    </button>
                </Link>
            </div>

        </div>
    );
};

export default TicketMobile;