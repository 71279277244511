import React from "react";
import "./Popup.modules.css";

function Popup({ onClose, children, title, show }) {
  return (
    <div className="popup">
      <div className="pop-inner">
        <div className="popup-header">
          <h3 className="popup-title">{title}</h3>
          <button aria-label="Close" className="close-icon" onClick={() => onClose()}>
            <i className="fas fa-times"></i>
          </button>
        </div>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}
export default Popup;
