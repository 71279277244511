import {Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import NotFound from './not-found';
const BookNowPage = lazy(() => import("./views/BookNowPage/BookNowPage"));
const PostPayment = lazy(() => import("./views/PostPayment/PostPayment"));
const BoatDetailsPage = lazy(() => import("./views/BoatDetailsPage/BoatDetailsPage"));
const AboutUS = lazy(() => import("./views/AboutUs/AboutUs"));
const Himara = lazy(() => import("./views/Himara/Himara"));
const Vlora = lazy(() => import("./views/Vlora/Vlora"));
const HowItWorks = lazy(() => import("./views/HowItWorks/HowItWorks"));
const LazyLoad = ({ children }) => <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
const routes = [
  { path: "/about-us", element: <LazyLoad><AboutUS /></LazyLoad> },
  { path: "/boatDetails", element: <LazyLoad><BoatDetailsPage /></LazyLoad> },
  { path: "/bookNow", element: <LazyLoad><BookNowPage /></LazyLoad> },
  { path: "/how-it-works", element: <LazyLoad><HowItWorks /></LazyLoad> },
  { path: "/himara", element: <LazyLoad><Himara /></LazyLoad> },
  { path: "/postPayment", element: <LazyLoad><PostPayment /></LazyLoad> },
  { path: "/vlora", element: <LazyLoad><Vlora /></LazyLoad> },
  { path: "*", element: <NotFound /> },
];

function App() {
  return (
    <div className="App" style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Routes>
        {routes.map((route, i) => <Route path={route.path} key={i} element={route.element} loader={route.loader} exact />)}
      </Routes>
    </div>
  );
}

export default App;
