/* React */
import React from "react";
import { useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";

/* Components */
import Popup from "../Popup";
import FlipMove from 'react-flip-move';
import Ticket from "../../Ticket/Ticket";

/* Styles */
import style from "./SearchListing.module.css";

/* Utils */
import { isSameDay } from "../../../utils/generalUtils";
import RecommendedItem from "../../Recommended/RecommendedItem";


const SearchListing = ({ resultList, loading }) => {

  const [params] = useSearchParams();
  const children = params.get("children");
  const adults = params.get("adults");
  const date = params.get("date");

  const [departures, setDepartures] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCity, setSelectedCity] = useState();
  const [numberOfAdult, setNumberOfAdult] = useState(2);
  const [numberOfChildren, setNumberOfChildren] = useState(0);

  const today = new Date(date);
  const tomorrow = new Date(today.setDate(today.getDate() + 1));

  const buildSearchParams = () => {
    let rawParams = {
      cityId: selectedCity,
      adults: numberOfAdult,
      children: numberOfChildren,
      date: tomorrow.toISOString().split("T")[0],
    };
    return new URLSearchParams(rawParams).toString();
  };

  useEffect(() => {
    setSelectedCity(
      params.get("cityId") || localStorage.getItem("selectedCity") || 0
    );
    setNumberOfAdult(adults || 2);
    setNumberOfChildren(children || 0);
  }, [params, adults, children]);

  const handlerClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (!resultList.length) {
      setShowPopup(true);
      setDepartures([]);
      return
    };

    setDepartures(resultList);
  }, [resultList, date]);

  return (
    <div className={style.recentListings}>
      {loading ? (
        <div className={style.searchListingLoader}>
          <div className={style.loader}></div>
        </div>
      ) : departures.length ? (
        departures.map(
          ({
            id,
            data
          }, index) => {
            return (
              <FlipMove
                key={index}
                staggerDurationBy="30"
                duration={500}
              >
                <RecommendedItem
                  item={data}
                  bookingData={{ adults: numberOfAdult, children: numberOfChildren, date: date }}
                  tripId={id}
                  key={`result-listing-${id}`}
                  onCardClick={() => { }}
                />
              </FlipMove>
            );
          }
        )
      ) : (
        <div className="Pop">
          {showPopup && (
            <Popup
              title={"No trips available"}
              onClose={handlerClose}
            >
              <div>
                <p className={style.popupNoTripsText}>
                  {" "}
                  Please try another date starting from{" "}
                  <Link to={`/searchList?${buildSearchParams()}`}>
                    the next day
                  </Link>
                </p>
              </div>
            </Popup>
          )}
          <div>No trips available</div>
        </div>
      )}
    </div>
  );
};

export default SearchListing;
