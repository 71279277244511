/* React */
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* Components */
import TicketMobile from "./TicketMobile";
import TicketDetails from "./TicketDetails";

/* Utils */
import { dateToStringDMV } from "../../utils/date-utils";
import { encodeContinueLink, decodeContinueLink } from "../../utils/urlUtils";
import { calculateTotal, timeToMinutes, convertMinutesToHour, formatPrice } from "../../utils/calcUtils";

const Ticket = ({
    boatId,
    title,
    date,
    adults,
    children,
    place,
    img,
    adultPrice,
    kidsPrice,
    time,
    category,
    times = [],
    showInfo
}) => {

    const [isMobile, setIsMobile] = useState(false);
    const [selectedTime, setSelectedTime] = useState(time);
    const [displayTimeInHour, setDisplayTimeInHour] = useState(time && convertMinutesToHour(time));

    useEffect(() => {
        if (!time) return;
        setSelectedTime(timeToMinutes(time));
        setDisplayTimeInHour(convertMinutesToHour(time));
    }, [time]);

    useEffect(() => {
        const checkScreenWidth = () => {
            const screenWidth = window.innerWidth;
            const mobileThreshold = 768;

            setIsMobile(screenWidth < mobileThreshold);
        };

        checkScreenWidth()

        window.addEventListener('resize', checkScreenWidth);
        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);


    const navigate = useNavigate();
    const total = calculateTotal(adults, adultPrice, children, kidsPrice);

    const navigateToTour = () => {
        return navigate(`/boatDetails?id=${boatId}&continue=${continueLink}`);
    };

    const continueLink = encodeContinueLink(
        boatId,
        adults,
        children,
        date,
        selectedTime ? (timeToMinutes(selectedTime)) : null
    );

    const handleTimeSelection = (time) => {
        setSelectedTime(time);
        setDisplayTimeInHour(convertMinutesToHour(time));
    }

    return (
        <>
            {isMobile ?
                <TicketMobile
                    boatId={boatId}
                    title={title}
                    date={dateToStringDMV(date, '.')}
                    adults={adults}
                    children={children}
                    place={place}
                    img={img}
                    times={times}
                    selectedTime={displayTimeInHour}
                    category={category}
                    total={formatPrice(total, 0)}
                    continueLink={continueLink}
                    navigateToTour={() => navigateToTour()}
                    handleTimeSelection={handleTimeSelection}
                    showInfo={showInfo}
                />
                :
                <TicketDetails
                    title={title}
                    date={dateToStringDMV(date, '.')}
                    adults={adults}
                    children={children}
                    place={place}
                    img={img}
                    times={times}
                    selectedTime={displayTimeInHour}
                    category={category}
                    total={formatPrice(total, 0)}
                    continueLink={decodeContinueLink(continueLink)}
                    navigateToTour={() => navigateToTour()}
                    handleTimeSelection={handleTimeSelection}
                />
            }
        </>
    );
};

export default Ticket;