import React, { useEffect, useState } from 'react';
import Popup from "../../Listing/Popup";

/* STYLES */
import style from './TimeRangeFilter.module.css';
import { setEngine } from 'crypto';


const TimeRangeFilter = ({ onTimeRangeChange, isReset, onReset }) => {

    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('23:59');
    const [error, setError] = useState('');
    

    useEffect(() => {
        if (isReset) {
            setStartTime('00:00')
            setEndTime('23:59')
            onReset()
        }
    }, [isReset,onReset])

    const handleTimeChange = (e) => {
        const { value, name } = e.target;
        if (name === 'startTime') {
            if(value < endTime){
                setStartTime(value)
                onTimeRangeChange({ startTime: value, endTime })
            }
            else{
                setError('The start time needs to be earlier than the end time.')
            }
        }
        else {
            if(value > startTime){
                setEndTime(value)
                onTimeRangeChange({ startTime, endTime: value })
            }
            else{
                setError('The start time needs to be earlier than the end time.')
            }
        }
    }

    const handlePopupClose = () => {
        setStartTime('00:00')
        setEndTime('23:59')
        setError('')
    }

    return (
        <>
            <div className={style.timeRangePickerWrapper}>
                <input
                    type="time"
                    name="startTime"
                    className={style.timeRangePicker}
                    value={startTime}
                    onChange={handleTimeChange}
                />
                <p>to</p>
                <input
                    type="time"
                    name="endTime"
                    className={style.timeRangePicker}
                    value={endTime}
                    onChange={handleTimeChange}
                />
            </div>
            
            {error && 
                <Popup onClose={handlePopupClose} title={"Time Range not valid"}>
                    <div className={style.noResult}>
                        <div className={style.noResultSubText}>{error}</div>
                    </div>
                </Popup>
            }
        </>
    );
};

export default TimeRangeFilter;
