const validDate = (selectedDate) => {
  const todaySplit = new Date();
  const convertDate = new Date(selectedDate);
  if (convertDate.getFullYear() > todaySplit.getFullYear()) {
    return true;
  } else if (convertDate.getFullYear() === todaySplit.getFullYear()) {
    if (convertDate.getMonth() > todaySplit.getMonth()) {
      return true;
    } else if (
      convertDate.getMonth() === todaySplit.getMonth() &&
      convertDate.getDate() >= todaySplit.getDate()
    ) {
      return true;
    }
    return false;
  }
  return false;
};

function isSameDay(date) {
  const today = new Date();
  if (date.getFullYear() !== today.getFullYear()) return false;
  if (date.getMonth() !== today.getMonth()) return false;
  if (date.getDay() !== today.getDay()) return false;
  return true;
}

const formatCurrency = (value) => {
  return new Intl.NumberFormat().format(value)
}

const createAvailabilityRanges = (data, active = false) => {
  const availableRanges = [];
  let currentRange = null;

  for (const entry of data) {
    if (entry.status === "Available") {
      if (!currentRange) {
        currentRange = { start: entry.date };
      }
    } else {
      if (currentRange) {
        currentRange.end = entry.date;
        availableRanges.push(currentRange);
        currentRange = null;
      }
    }
  }

  if (active && availableRanges.length > 0) {
    const firstRange = availableRanges[0];
    firstRange.start = "0000-01-01";
  }

  if (data.length > 0 && data[0].status === "Available") {
    availableRanges.unshift({ start: "0000-01-01", end: data[0].date });
  }

  return availableRanges;
}

export { validDate, isSameDay, formatCurrency, createAvailabilityRanges };
