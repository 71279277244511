import React from 'react';
import style from './TimeGallery.module.css';

const TimeGallery = ({ times, selectedTime, setSelectedTime }) => {
    const currentIndex = times.indexOf(selectedTime);
    const isAtBeginning = currentIndex === 0;
    const isAtEnd = currentIndex === times.length - 1;

    const handlePrevTime = () => {
        const currentIndex = times.indexOf(selectedTime);
        if (currentIndex > 0) {
            setSelectedTime(times[currentIndex - 1]);
        }
    };

    const handleNextTime = () => {
        const currentIndex = times.indexOf(selectedTime);
        if (currentIndex < times.length - 1) {
            setSelectedTime(times[currentIndex + 1]);
        }
    };

    return (
        <div className={style.galleryContainer}>
            <div className={style.arrowWrapper}>
                {times && times.length > 1 && (
                    <>
                        <button
                            aria-label="Previous Time"
                            className={`${style.arrowButton} ${style.prevButton} ${isAtBeginning ? style.disabledArrowTime : ''}`}
                            onClick={handlePrevTime}
                            disabled={isAtBeginning}
                        >
                            {"<"}
                        </button>

                        <div className={style.timeDisplay}>
                            <p>{selectedTime}</p>
                        </div>

                        <button
                            aria-label="Next Time"
                            className={`${style.arrowButton} ${style.nextButton} ${isAtEnd ? style.disabledArrowTime : ''}`}
                            onClick={handleNextTime}
                            disabled={isAtEnd}
                        >
                            {">"}
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default TimeGallery;
