/* React */
import { Helmet } from 'react-helmet';
import React, { lazy, Suspense, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

/* Styles */
import style from './HomePage.module.css';

/* Components */
import Loading from '../../loading';
import Sidebar from '../../components/Sidebar/Sidebar';

/* Utils */
import Schema from '../../utils/schemas.json';
import SearchComponent from '../../components/Search/SearchComponent';

/* Lazy components */
const BannerTop = lazy(() => import('../../components/BannerTop/BannerTop'));
const RecentListings = lazy(() => import('../../components/Listing/Recent/RecentListings'));
const HomeBanner = lazy(() => import('../../components/HomeBanner/HomeBanner'));
const SHome = lazy(() => import('../../components/SHome/SHome'));
const Recommended = lazy(() => import('../../components/Recommended/Recommended'));
const HappyCustomers = lazy(() => import('../../components/HappyCustomers/HappyCustomers'));
const Footer = lazy(() => import('../../components/Footer/Footer'));

const HomePage = () => {

  const { data } = useLoaderData();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Boat Trips and Tour in Albania | Ionian Coasts</title>
        <link rel="canonical" href="https://www.boattrips.al" />
        <meta
          name="description"
          content="Explore the coasts of Albania with our Boat Tours and Trips! Tourism packages boat ride for family holiday or a group. Choose your boat and book a trip!"
        />
        <meta
          name="keywords"
          content="Boat Tour, Boat Trip, adventure,tourism, boat ride, tour boat,the boat trip,book boat, trip vacation,family holidays,group getaway, book travel packages "
        />

        <script type="application/ld+json">
          {JSON.stringify(Schema[0])}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(Schema[1])}
        </script>
      </Helmet>

      <div className={style.pageWrapper}>
        <Sidebar />

        <div className={style.contentWrapper}>
          <div className={style.homePageWrapper}>

            <div className={`row row-lg f-100`}>
              <div className={style.componentBoxSearch}>

                {
                  data.length !== 0
                    ? <SearchComponent cities={data} />
                    : <Loading />
                }
              </div>

              <div className="col f-50 d-none-lg">
                <Suspense fallback={<Loading />}>
                  <HomeBanner />
                </Suspense>
              </div>
            </div>
          </div>
          <Suspense fallback={<Loading />}>
            <SHome />
            <div className={style.recommendedMain}>
              <Recommended />
            </div>
            <HappyCustomers />
            <BannerTop />
            <Footer />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default HomePage;

