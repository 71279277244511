/* React */
import React from "react";
import { Link } from "react-router-dom";

/* Components */
import TimeGallery from "../Filters/TimeGallery/TimeGallery";

/* Styles */
import style from "./Ticket.module.css";

const TicketDetails = ({
    boatId,
    title,
    date,
    adults,
    children,
    place,
    img,
    selectedTime,
    times,
    total,
    category,
    navigateToTour,
    continueLink,
    handleTimeSelection,
    showInfo
}) => {

    return (
        <div className={style.itemWrapper}>
            <div className={style.mainWrapepr}>
                <div className={style.imageWrapper} onClick={navigateToTour}>
                    <img
                        src={`${!!img ? img : process.env.PUBLIC_URL + "/favicon.ico"}`}
                        alt={title}
                        className="cursor-pointer"
                    />
                    {showInfo && (
                        <Link to={`/boatDetails?id=${boatId}&continue=${continueLink}`}>
                            <button
                                aria-label="Info" className={`${style.buttonTourInfo} cursor-pointer`}>
                                Info
                            </button>
                        </Link>
                    )}
                </div>

                <div className={style.infoWrapper}>
                    <div className={style.ticketInfo} onClick={navigateToTour}>
                        <div
                            className={`${style.title} cursor-pointer`}
                        >
                            {title}
                        </div>
                        <div className={style.infoTag}>{category}</div>
                    </div>

                    <div className={style.moreInfoWrapper}>
                        <div className={style.iconWrapper} onClick={navigateToTour}>
                            <div className={style.iconListCalendar}>
                                <i
                                    className={`${style.iconListItem} fas fa-calendar-alt d-none-sm `}
                                ></i>
                                <div className={style.startTripDate}>{date}</div>
                            </div>
                            <div className={style.peopleList}>
                                <div className={style.iconList}>
                                    <i className={`${style.iconListItem} fa fa-male fa-lg`}></i>
                                    <span>x</span>
                                    <div className={style.peopleNumber}>{adults}</div>
                                </div>
                                <div className={style.iconListChild}>
                                    <i className={`${style.iconListItem} fa fa-child `}></i>
                                    <span>x</span>
                                    <div className={style.peopleNumber}>{children}</div>
                                </div>
                            </div>
                        </div>

                        <div className={style.depAndTime}>
                            {place && (<p className={style.depCity}>{place}</p>)}
                            {times && times.length > 1 ?
                                <TimeGallery
                                    times={times}
                                    selectedTime={selectedTime}
                                    setSelectedTime={handleTimeSelection}
                                />
                                :
                                <p className={style.timeOfDep}>{selectedTime}</p>
                            }
                        </div>

                    </div>
                </div>
            </div>

            <div className={style.buttonWrapper}>
                <div className={style.pricePerPerson}>{total} L</div>
                <Link to={`/bookNow?${continueLink}`}>
                    <button
                        aria-label="Book Now" className={style.itemButton}>
                        Book now
                    </button>
                </Link>
            </div>

        </div>
    );
};

export default TicketDetails;