import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './views/Home/HomePage';
import './index.css';
import NotFound from './not-found';
import App from './App';
import SearchListPage from './views/SearchList/SearchListPage';
import { FetchListings } from './__utils__';
ReactDOM.render(
  // <React.StrictMode>
  <RouterProvider router={createBrowserRouter([
    { path: "/", loader: () => import('./__utils__').then(m => m.FetchData('cities')), errorElement: () => import('./error'), element: <HomePage />, },
    { path: "/searchList", loader: FetchListings, element: <SearchListPage />, },
    { path: "/*", element: <App /> },
    { path: "*", element: <NotFound /> },
  ])} />,
  // </React.StrictMode>,
  document.getElementById("root")
);