// /**
//  * Returns an object from a URLSearchParams instance
//  * @param {URLSearchParams} urlParamObject
//  * @returns
//  */
export const destructParams = (urlParamObject) => {
  if (!(urlParamObject instanceof URLSearchParams))
    throw new Error(`'urlParamObject' is not a 'URLSearchParams' instance`);
  return Object.fromEntries(urlParamObject)
};

export const encodeContinueLink = (boatId, adults, children, date, time, encode = true) => {
  const searchParams = new URLSearchParams({
    boatId,
    adults,
    children,
    date,
    time,
  });
  return encode ? encodeURIComponent(searchParams.toString()) : searchParams.toString();
};

export const decodeContinueLink = (encodedLink) => {
  return decodeURIComponent(encodedLink);
};
