import React from 'react';
import boatcover from "./assets/img/olympia8-1.webp";
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

const NotFound = () => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Not Found Page | Boattrips</title>
        <link rel="canonical" href={`https://www.boattrips.al${location.pathname}`} />
      </Helmet>
      <div className="not-found-container" style={{ backgroundImage: `url('${boatcover}')` }} >
        <span className='not-found-overlay' />
        <img src={'/logo.svg'} alt="Website Logo" className="not-found-logo" />
        <h1 className='not-found-h1'>Page Not Found</h1>
        <p className='not-found-p' >
          The page you requested could not be found. It might be unavailable
          temporarily, or it might have moved.
        </p>
        <Link to="/" className="not-found-link">
          Go Back Home
        </Link>
      </div>
    </>
  );
};

export default NotFound;
