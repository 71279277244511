import { useEffect, useState } from 'react'
import style from './Filters.module.css'
import PriceFilter from '../../components/Filters/PriceFilter/PriceFilter';
import TimeRangeFilter from '../../components/Filters/TimeRange/TimeRangeFilter';
import Accordion from '../../components/Accordion/Accordion';


const Filters = ({ categories, durations, onButtonClick, MAX_PRICE_VALUE, MIN_PRICE_VALUE, forceReset, handleForceReset, isMobile }) => {

    const [minPrice, setMinPrice] = useState(MIN_PRICE_VALUE);
    const [maxPrice, setMaxPrice] = useState(MAX_PRICE_VALUE);
    const [timeRange, setTimeRange] = useState({ startTime: '00:00', endTime: '23:59' });

    useEffect(() => {
        setMinPrice(MIN_PRICE_VALUE)
        setMaxPrice(MAX_PRICE_VALUE)
    }, [MAX_PRICE_VALUE, MIN_PRICE_VALUE])

    useEffect(() => {
        if (forceReset) {
            handleResetFilter()
            handleForceReset()
        }
    }, [forceReset])


    const [checkedCategories, setCheckedCategories] = useState([]);
    const [checkedDurations, setCheckedDurations] = useState([]);
    const [isReset, setIsReset] = useState(false);

    const handleFilter = () => {
        onButtonClick({ checkedCategories, minPrice, maxPrice, checkedDurations, timeRange });
    }

    useEffect(() => {
        handleFilter()
    }, [checkedCategories, checkedDurations, minPrice, maxPrice, timeRange])

    const handleCategoryFilter = (e) => {
        const category = e.target.value;

        if (e.target.checked) setCheckedCategories([...checkedCategories, category]);
        else setCheckedCategories(checkedCategories.filter(c => c !== category))
    }

    const handleDurationFilter = e => {
        const duration = e.target.value;

        if (e.target.checked) setCheckedDurations([...checkedDurations, duration])
        else setCheckedDurations(checkedDurations.filter(c => c !== duration))
    }

    const handlePriceChange = (min, max) => {
        setMinPrice(min)
        setMaxPrice(max)
    };

    const handleTimeRangeChange = (range) => {
        setTimeRange(range)
    }

    const handleResetFilter = () => {
        setIsReset(true)
        setCheckedCategories([]);
        setCheckedDurations([])
        setMaxPrice(MAX_PRICE_VALUE)
        setMinPrice(MIN_PRICE_VALUE)
        setTimeRange({ startTime: '00:00', endTime: '23:59' })
        onButtonClick({
            checkedCategories: [],
            minPrice: MIN_PRICE_VALUE,
            maxPrice: MAX_PRICE_VALUE,
            checkedDurations: [],
            timeRange: { startTime: '00:00', endTime: '23:59' }
        });
    }

    return (
        <div className={isMobile ? style.filterMobile : style.filter}>

            <div className={""}>
                <Accordion name="Category" isFirst>
                    {categories.map((category, key) => {
                        return (
                            <div className={style.chechboxes} key={key}>
                                <label key={key} className={style.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        className={style.checkboxInput}
                                        value={category}
                                        key={key}
                                        name="c-group"
                                        checked={checkedCategories.includes(category)}
                                        onChange={handleCategoryFilter}
                                    />
                                    <span className={style.checkBoxText}>{category}</span>
                                </label>
                            </div>
                        )
                    })}
                </Accordion>


                {durations?.length > 0 && (
                    <>
                        <Accordion name="Duration">
                            {durations.map((duration, key) => {
                                return (
                                    <div className={style.chechboxes} key={key}>
                                        {duration && (
                                            <label htmlFor={key} className={style.checkboxLabel}>
                                                <input
                                                    type="checkbox"
                                                    className={style.checkboxInput}
                                                    value={duration}
                                                    id={key}
                                                    name="c-group"
                                                    checked={checkedDurations.includes(duration)}
                                                    onChange={handleDurationFilter}
                                                />
                                                <span className={style.checkBoxText}>{duration}</span>
                                            </label>
                                        )}
                                    </div>
                                )
                            })}
                        </Accordion>
                    </>
                )}

                <Accordion name="Departure Time">
                    <TimeRangeFilter
                        onTimeRangeChange={(range) => handleTimeRangeChange(range)}
                        isReset={isReset}
                        onReset={() => setIsReset(false)}
                    />
                </Accordion>

                {/* <Accordion name="Price">
                    <div className={style.priceFilter}>
                        <div className={style.textFilter}>Price (ALL)</div>
                        <PriceFilter
                            MAX_PRICE_VALUE={MAX_PRICE_VALUE}
                            MIN_PRICE_VALUE={MIN_PRICE_VALUE}
                            onPriceChange={handlePriceChange}
                            isReset={isReset}
                            onReset={() => setIsReset(false)}
                        />
                        <div className={style['slider-text']}>
                            <p>{new Intl.NumberFormat().format(minPrice)} </p>
                            {minPrice === maxPrice ? <></> :
                                <>
                                    to
                                    <p> {new Intl.NumberFormat().format(maxPrice)}</p>
                                </>
                            }
                            ALL
                        </div>
                    </div>
                </Accordion> */}
            </div>

            <div className={style.buttons}>
                <button className={style.resetButton} onClick={handleResetFilter}>Reset</button>
            </div>
        </div>
    )
}
export default Filters;