/* React */
import React, { forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";

/* Utils */
import { timeToMinutes } from "../../utils/calcUtils";
import { encodeContinueLink } from "../../utils/urlUtils";
import { formatCurrency } from "../../utils/generalUtils";
import BoatImage from "../../assets/img/boattrip.webp"

/* Styles */
import style from "./RecommendedItem.module.css";

const RecommendedItem = forwardRef(({ item, tripId, onCardClick, bookingData = {} }, ref) => {

  const navigate = useNavigate();
  const time = parseInt(item.times[0].split(':').join(''), 10);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split('T')[0];

  const tripDetails = {
    id: tripId,
    adults: bookingData.adults ?? 1,
    children: bookingData.children ?? 0,
    date: bookingData.date ?? tomorrowFormatted,
    time: timeToMinutes(time)
  }

  const continueLink = encodeContinueLink(
    tripId,
    tripDetails.adults,
    tripDetails.children,
    tripDetails.date,
    tripDetails.time
  )

  const navigateToTour = () => {
    onCardClick && onCardClick();
    return navigate(
      `/boatDetails?id=${tripId}&continue=${continueLink}`
    );
  };

  return (
    <Link className={style.cardLink} to={`/boatDetails?id=${tripId}&continue=${continueLink}`}>
      <div className={style.itemWrapper} ref={ref}>
        <img className={style.itemImage + " cursor-pointer"} src={item.image[0] ? item.image[0] : BoatImage} alt={item.name} onClick={navigateToTour} />
        <div className={style.itemTitle + " cursor-pointer"} onClick={navigateToTour}>{item.name}</div>
        {item?.info && (
          <p className={style.itemInfo}>{item.info && item.info.split(' ').slice(0, 25).join(' ')}{item.info && item.info.split(' ').length > 25 ? '...' : ''}</p>
        )}
        <div className={style.itemIconsInfo}>
          {item?.amenities && item.amenities.length > 0 && item.amenities
            .filter(amenity => amenity.available)
            .slice(0, 5)
            .map((amenity, index) => (
              <div className={style.amenityIconRecommended} key={index}>
                <i key={index} className={`${amenity.icon}`}></i>
              </div>
            ))}
        </div>
        <div className={style.itemOtherInfo}>
          <div className={style.infoTag}>{item.category}</div>
          <div className={style.itemPrice}>{`ALL ${formatCurrency(item.totalCost)}`}</div>
        </div>
      </div>
    </Link>
  );
});

export default RecommendedItem;
