import React from "react";
import SearchListing from "../Listing/Recent/SearchListing";
import style from "./SearchBanner.module.css";
import { useEffect, useRef, useState } from "react";
import { timeToMinutes } from "../../utils/calcUtils";
import Popup from "../Listing/Popup";

const filterByCategories = (listing, categories) => {
  if (categories.length === 0) return true;

  return categories.includes(listing.data.category)
}

const filterByDuration = (listing, durations) => {
  if (durations.length === 0) return true;

  return durations.includes(listing.data.duration)
}

const filterByPrice = (listing, minPrice, maxPrice) => {
  if (!maxPrice && !minPrice) return true;
  return listing.data.totalCost <= maxPrice && listing.data.totalCost >= minPrice;
}

const filterByTimeRange = (times, timeRange) => {
  if (!timeRange || !times || times.length === 0) return [];

  const { startTime, endTime } = timeRange;

  return times.filter((time) => {
    const timeInMinutes = timeToMinutes(time);
    return timeInMinutes >= timeToMinutes(startTime) && timeInMinutes <= timeToMinutes(endTime);
  });
};


const SearchBanner = ({ resultList, loading, filters, handleReset }) => {
  const [filteredList, setFilteredList] = useState()

  const scrollRef = useRef(null);

  useEffect(() => {
    if (!resultList || !filters) return;
    const { checkedCategories, checkedDurations, minPrice, maxPrice, timeRange } = filters

    let result = resultList.map((listing) => {
      const filteredTimes = filterByTimeRange(listing.data.times, timeRange);
      if (filteredTimes.length === 0) return null;
      return {
        ...listing,
        data: {
          ...listing.data,
          times: filteredTimes,
        },
      };
    });

    result = result
      .filter(listing => { return listing !== null })
      .filter(listing => { return filterByCategories(listing, checkedCategories) })
      .filter(listing => { return filterByDuration(listing, checkedDurations) })
      // .filter(listing => { return filterByPrice(listing, minPrice, maxPrice) })

    setFilteredList(result)
  }, [filters, resultList])

  const handlePopupClose = () => {
    setFilteredList(null)
    handleReset()
  }

  return (
    <div ref={scrollRef} className={style.bannerWrapper}>
      {filteredList && filteredList.length === 0 ?
        <Popup onClose={handlePopupClose} title={"No results found"}>
          <div className={style.noResult}>
            <div className={style.noResultText}>Try adjusting your search. Here are some ideas:</div>
            <div className={style.noResultSubText}>- Check the time range</div>
            <div className={style.noResultSubText}>- Change your search filters</div>
          </div>
        </Popup>
        :
        <SearchListing resultList={filteredList ?? resultList} loading={loading} />
      }
    </div>
  );
};

export default SearchBanner;
