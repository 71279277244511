const URLS = {
  production: "/api/v1",
  development: "/api/v1"
};
const BASE_URL = URLS[process.env.NODE_ENV]
const fetchCities = async () => {
  const storedDataString = localStorage.getItem('cities');
  let data;
  let lastCachedTime;

  try {
    if (storedDataString) {
      // Parse stored data (handle potential errors)
      try {
        const storedData = JSON.parse(storedDataString);
        data = storedData.data;
        lastCachedTime = storedData.timestamp;
      } catch (parseError) {
        console.error('Error parsing stored data:', parseError);
        // Optionally handle parsing errors (e.g., clear invalid data)
      }
    }

    // Check if data exists and cache is not expired
    if (data && lastCachedTime && Date.now() - lastCachedTime < 15 * 60 * 1000) {
      return data;
    }

    // Fetch data if cache is expired or not found
    const response = await fetch('/api/v1/cities');
    const asset = await response.json();

    if (asset.status !== 'success') {
      throw new Error(`API request failed with status ${response.status}`);
    } else {
      data = asset.data;
      localStorage.setItem('cities', JSON.stringify({ data, timestamp: Date.now() })); // Store fetched data with timestamp
    }
  } catch (error) {
    console.error('Error fetching or parsing data:', error);
    // Optionally throw error for specific handling in calling code
    throw error;
  }

  return data;
};

export const FetchData = async (endpoint) => {
  try {
    if (endpoint === 'cities') {
      const data = await fetchCities();
      return { data }
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export async function FetchListingManually( url) {
  let data;
  try {
    const res = await fetch(`/api/v1/boats/available?${url}`);
    data = await res.json();
    data = data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
  return { data};
}
export async function FetchListings({ request }) {
  const url = new URL(request.url);
  let cities;
  let data;
  try {
    cities = await fetchCities();
    const res = await fetch(`/api/v1/boats/available${url.search}`);
    data = await res.json();
    data = data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
  return { data, cities };
}
export const fetchBoat = async id => {
  return (await fetch(`${BASE_URL}/boats/${id}`)).json()
}

export const placeOrder = async (boatId, email, time, date, tickets, contact, address, amenitiesData) => {
  const res = await fetch(`${BASE_URL}/orders/place`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ boatId, email, time, date, tickets, contact, address, amenities: amenitiesData })
  })
  return res.json();
}

export const getOrderById = async (id) => {
  const res = await fetch(`${BASE_URL}/orders/${id}`)
  return res.json();
}

export const fetchBoatAvailability = async (id, bookInfo) => {
  const res = await fetch(`${BASE_URL}/boats/available/${id}`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bookInfo)
  })
  return res.json();
}

export const truncate = (txt, maxLen) => {
  return (txt.length > maxLen ? txt.slice(0, maxLen - 1) + "..." : txt);
}

export const truncateSentence = (txt, maxLen) => {
  return (txt.split(' ')?.length > maxLen ? txt.split(' ').slice(0, maxLen).join(' ') + "..." : txt)
}